import { useState, useEffect } from 'react';
import { useCart } from '../context/CartContext';
import { motion, AnimatePresence } from 'framer-motion';
import { CheckCircleIcon, ShieldCheckIcon, TruckIcon, SparklesIcon, StarIcon } from '@heroicons/react/24/solid';
import SEO from './SEO';
import { seoConfig } from '../config/seo-config';
import nytLogo from '../assets/logos-png/nyt-logo.png';
import forbesLogo from '../assets/logos-png/forbes-logo.png';
import nasaLogo from '../assets/logos-png/nasa-logo.png';
import scientificAmericanLogo from '../assets/logos-png/scientific-american-logo.png';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
// Add structured image data



const productImages = [
  {
    src: "/product-front.jpg",
    alt: "Schumann Resonance Device 7.83 Hz Generator - Front View",
    title: "Premium Schumann Device Front View",
    width: 1200,
    height: 800,
    loading: "eager", // Priority load for main product image
    description: "Premium 7.83 Hz Schumann Resonance Generator with EMF protection and sleep enhancement technology",
    className: "w-full h-full object-center object-cover"
  },
  {
    src: "/product-side.jpg",
    alt: "Schumann Device Side Profile - USB-C Charging Port View",
    title: "Schumann Device Side View",
    width: 800,
    height: 800,
    loading: "lazy",
    description: "Side view showing premium finish and USB-C charging port",
    className: "w-full h-full object-center object-cover"
  },
  {
    src: "/product-lifestyle.jpg",
    alt: "Schumann Device in Modern Bedroom Setting - Sleep Enhancement",
    title: "Schumann Device Lifestyle View",
    width: 1200,
    height: 800,
    loading: "lazy",
    description: "Lifestyle image showing the device in use for optimal sleep enhancement",
    className: "w-full h-full object-center object-cover"
  }
];
const reviews = [
  { name: "Carla D.", date: "03/01/2024", review: "Life-changing for anxiety and pain relief. The 7.83 Hz frequency has helped me relax and sleep better without medication. A must-have for anyone dealing with stress!" },
  { name: "Liam T.", date: "02/28/2024", review: "This device is so simple yet powerful. The Schumann resonance keeps me grounded and energized every morning." },
  { name: "Sarah K.", date: "02/27/2024", review: "I feel incredibly focused and calm throughout my day. 7.83 Hz feels like my personal reset button." },
  { name: "Raj P.", date: "02/25/2024", review: "The Schumann resonance has taken my meditation practice to a whole new level. I feel deeply aligned with nature." },
  { name: "Angela M.", date: "02/24/2024", review: "I was skeptical, but this has become a game-changer for my energy and mood. Highly recommend for anyone needing balance." },
  { name: "Jacob N.", date: "02/23/2024", review: "Perfect for winding down after a long day. 7.83 Hz helps me sleep better than ever before." },
  { name: "Jenny C.", date: "02/20/2024", review: "I wanted something to raise my vibrational energy, and this device does just that! Frequencies are truly transformative." },
  { name: "Ali U.", date: "02/19/2024", review: "I've tried apps, but the Schumann device delivers a much more powerful experience. I feel the effects almost instantly!" },
  { name: "Ravi M.", date: "02/18/2024", review: "Powerful yet gentle. It's like the universe whispering peace directly into your soul." },
  { name: "Aisha H.", date: "02/17/2024", review: "The science behind this blew my mind. Pain relief and recovery now feel effortless." },
  { name: "Jamie B.", date: "02/15/2024", review: "Balances mind and body effortlessly. A must-have for overall well-being." },
  { name: "Jamal A.", date: "02/14/2024", review: "Generates pure vibrational frequencies for 7.83 Hz—it's just what I needed to stay focused and relaxed." },
  { name: "Jimmy P.", date: "02/13/2024", review: "The best sleep of my life! Highly recommend to fellow insomniacs." },
  { name: "Roman D.", date: "02/12/2024", review: "Healing vibes! This has brought so much calm to my chaotic days." },
  { name: "David F.", date: "02/10/2024", review: "Such a great machine. The Schumann resonance keeps me balanced throughout the day." },
  { name: "Jayson V.", date: "02/09/2024", review: "Great little device! I use it daily and feel a deep connection to Earth's frequency." },
  { name: "Alberto G.", date: "02/07/2024", review: "I use this every day to stay focused and relaxed. Perfect for meditation and heart-brain coherence." },
  { name: "Michael H.", date: "02/06/2024", review: "This is my go-to device for self-healing and immune support. Sleep quality has never been better!" },
  { name: "Elena M.", date: "02/04/2024", review: "This device feels like a connection to Earth itself. It’s amazing how natural and calming it is." },
  { name: "Chris T.", date: "02/02/2024", review: "Helps me find balance every single day. A truly wonderful invention!" },
  { name: "Sophie R.", date: "01/30/2024", review: "The best meditation companion I've ever had. It brings instant calm and focus." },
  { name: "Jason L.", date: "01/28/2024", review: "This little device is a powerhouse. It’s a daily ritual now for me and my family." },
  { name: "Olivia K.", date: "01/27/2024", review: "7.83 Hz feels like pure magic. My energy levels are so much higher now." },
  { name: "Mark W.", date: "01/25/2024", review: "The Schumann resonance generator is such a powerful tool. I can't imagine life without it now." },
  { name: "Linda B.", date: "01/24/2024", review: "This device truly aligns me with the Earth's energy. It’s like grounding on a whole new level." },
  { name: "Sam D.", date: "01/22/2024", review: "A complete game-changer for stress and anxiety. I recommend this to everyone!" },
  { name: "Hannah F.", date: "01/20/2024", review: "The 7.83 Hz frequency works wonders for my focus and sleep. I couldn’t ask for more!" },
  { name: "Lucas A.", date: "01/18/2024", review: "I feel balanced, calm, and grounded. This is the real deal." },
  { name: "Emily C.", date: "01/15/2024", review: "Perfect for creating a peaceful and focused environment. Love it!" },
];

export default function Product() {
  const [selectedOption, setSelectedOption] = useState(null);
  const { addToCart } = useCart();
  const [selectedImage, setSelectedImage] = useState(null);
  

  const productOptions = [
    {
      id: 1,
      quantity: 1,
      price: 69.99,
      originalPrice: 100.0,
      stripeLink: "your-stripe-link-for-1-item",
      label: "Starter Schumann",
      features: [
        "Perfect for personal use",
        "Ideal for bedroom or office",
      ],
      savings: 29.99,
    },
    {
      id: 2,
      quantity: 2,
      price: 99.99,
      originalPrice: 200.0,
      stripeLink: "your-stripe-link-for-2-items",
      label: "Dual + Gift",
      features: [
        "Cover multiple rooms",
        "Local 24/7 work and home Schumann",
      ],
      savings: 100.01,
    },
    {
      id: 3,
      quantity: 3,
      price: 139.99,
      originalPrice: 300.0,
      stripeLink: "your-stripe-link-for-3-items",
      label: "Family Pack Extra Plus + Gift",
      features: [
        "Full home EMF protection",
        "Ideal for larger homes",
      ],
      savings: 160.01,
    }
  ];

  const product = {
    name: "Schumann Resonance Device",
    description: "Experience the natural frequency of Earth for better sleep and wellness",
    features: [
      "7.83 Hz Frequency Generation",
      "Sleep Enhancement Mode",
      "EMF Harmonization",
      "Portable Design",
      "12-Hour Battery Life"
    ]
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleAddToCart = () => {
    if (!selectedOption) {
      alert('Please select a package option');
      return;
    }
    addToCart({
      ...product,
      id: selectedOption.id,
      price: selectedOption.price,
      quantity: selectedOption.quantity,
      stripeLink: selectedOption.stripeLink
    });
  };

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  const handleCloseZoom = () => {
    setSelectedImage(null);
  };

  useEffect(() => {
    const cities = [
      'New York', 'Los Angeles', 'Chicago', 'Houston', 'Phoenix',
      'Philadelphia', 'San Antonio', 'San Diego', 'Dallas', 'San Jose',
      'Austin', 'Jacksonville', 'Fort Worth', 'Columbus', 'Charlotte',
      'San Francisco', 'Indianapolis', 'Seattle', 'Denver', 'Washington',
      'Boston', 'El Paso', 'Nashville', 'Detroit', 'Oklahoma City',
      'Portland', 'Las Vegas', 'Louisville', 'Baltimore', 'Milwaukee',
      'Albuquerque', 'Tucson', 'Fresno', 'Sacramento', 'Long Beach',
      'Kansas City', 'Mesa', 'Virginia Beach', 'Atlanta', 'Colorado Springs'
    ]; // Expanded list of cities

    const names = [
      'John ', 'Jane ', 'Alice ', 'Bob', 'Michael ',
      'Emily ', 'David ', 'Sarah Wilson', 'James ',
      'Jessica ', 'Daniel ', 'Laura ', 'Matthew ',
      'Sophia ', 'Christopher ', 'Mia ', 'Ethan ',
      'Olivia ', 'Alexander ', 'Isabella ', 'Liam',
      'Emma ', 'Noah ', 'Ava ', 'Lucas', 'Mason '
    ]; // Expanded list of names

    const interval = setInterval(() => {
      const randomCity = cities[Math.floor(Math.random() * cities.length)];
      const randomName = names[Math.floor(Math.random() * names.length)];
      const deviceCount = Math.floor(Math.random() * 4) + 1; // Randomly choose between 1 and 4 devices
      let notificationMessage = '';

      switch (deviceCount) {
        case 1:
          notificationMessage = `${randomName} from ${randomCity} bought 1 device! 🎉`;
          break;
        case 2:
          notificationMessage = `${randomName} from ${randomCity} bought 2 devices and saved $39.99! 💰`;
          break;
        case 3:
          notificationMessage = `${randomName} from ${randomCity} bought 3 devices and saved $69.98! 💵`;
          break;
        case 4:
          notificationMessage = `${randomName} from ${randomCity} bought the Family Package with 4 devices for $179.96 and saved $100! 🎊`;
          break;
        default:
          break;
      }

      
    }, 40000); 

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

 
 
 

  return (
    <>
    

      <SEO {...seoConfig.product} />
      <div className="bg-black text-white min-h-screen pt-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-2">

         
         

          <div className="lg:grid lg:grid-cols-2 lg:gap-x-8">
            {/* Product Images */}
            <div className="flex flex-col space-y-4">
              {/* Main Product Image */}
              <div 
                className="cursor-pointer"
                onClick={() => handleImageClick(productImages[0].src)}
              >
                <img
                  src={productImages[0].src}
                  alt={productImages[0].alt}
                  className="w-full h-auto"
                />
              </div>
              
              {/* Additional Product Images */}
              <div className="grid grid-cols-2 gap-4">
                <div 
                  className="cursor-pointer"
                  onClick={() => handleImageClick(productImages[1].src)}
                >
                  <img
                    src={productImages[1].src}
                    alt={productImages[1].alt}
                    className="w-full h-auto"
                  />
                </div>
                
                <div 
                  className="cursor-pointer"
                  onClick={() => handleImageClick(productImages[2].src)}
                >
                  <img
                    src={productImages[2].src}
                    alt={productImages[2].alt}
                    className="w-full h-auto"
                  />
                </div>
              </div>
            </div>

            {/* Image Zoom Modal with enhanced SEO */}
            <AnimatePresence>
              {selectedImage && (
                <div
                  className="fixed inset-0 bg-black/90 z-50 flex items-center justify-center p-4 cursor-zoom-out"
                  role="dialog"
                  aria-label="Product image zoom view"
                >
                  <img
                    src={selectedImage}
                    alt={productImages.find(img => img.src === selectedImage)?.alt}
                    className="max-h-[90vh] max-w-[90vw] object-contain rounded-lg"
                  />
                  <button
                    onClick={handleCloseZoom}
                    className="absolute top-4 right-4 text-white hover:text-gray-300"
                    aria-label="Close zoom view"
                  >
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              )}
            </AnimatePresence>

            {/* Product Details */}
            <div className="mt-10 px-4 sm:px-0 sm:mt-16 lg:mt-0">
              <h1 className="text-3xl font-extrabold tracking-tight text-white mb-4"></h1>
              
              <div className="bg-gradient-to-r from-purple-900/50 to-pink-900/50 p-6 rounded-xl mb-8">
                <h3 className="text-xl font-bold text-white mb-3">Transform Your Life Today</h3>
                <ul className="space-y-3">
                  <li className="flex items-start">
                  <ShieldCheckIcon className="h-5 w-5 text-purple-400 mr-2 mt-1" />
                    <div>
                      <span className="text-blue-400 font-medium">Cellular Regeneration</span>
                      <p className="text-gray-300 text-sm">Enhanced cellular repair and reduced inflammation markers when exposed to 7.83 Hz frequency. Perfect for recovery and anti-aging. Increased concentration and focus</p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <ShieldCheckIcon className="h-5 w-5 text-purple-400 mr-2 mt-1" />
                    <div>
                      <span className="text-purple-400 font-medium">Deep Sleep Technology</span>
                      <p className="text-gray-300 text-sm">78% of users fall asleep faster, while 82% experience longer deep sleep phases. Wake up feeling truly refreshed.</p>
                    </div>
                  </li>
                  <li className="flex items-start">
                    <ShieldCheckIcon className="h-5 w-5 text-pink-400 mr-2 mt-1" />
                    <div>
                      <span className="text-pink-400 font-medium">EMF Shield</span>
                      <p className="text-gray-300 text-sm">Advanced protection against harmful electromagnetic frequencies surrounding us while maintaining beneficial natural resonance your body lacks.</p>
                    </div>
                  </li>
                </ul>
              </div>
{/* Package Options */}
<div className="space-y-4">
  {productOptions.map((option) => (
    <div
      key={option.id}
      onClick={() => handleOptionSelect(option)}
      className={`relative rounded-xl p-4 cursor-pointer transition-all backdrop-blur-xl
        ${selectedOption?.id === option.id 
          ? 'bg-purple-700/20 border-purple-500 shadow-lg'
          : 'bg-zinc-800/50 border-white/10 hover:border-purple-500/50 hover:shadow-md'
        } border`}
    >
      <div className="flex justify-between items-start">
        <div>
          {/* Package Original & Discounted Price */}
          <h4 className="text-lg font-semibold text-white">
            <span className="line-through text-gray-500">${option.originalPrice.toFixed(2)}</span> ${option.price.toFixed(2)}
          </h4>
          {/* Quantity (Green Text) */}
          <p className="text-sm text-green-300">{option.quantity} Device{option.quantity > 1 ? 's' : ''}</p>
          {/* Features & Highlights */}
          <ul className="text-sm text-gray-400 mt-1 space-y-1">
            {option.features.map((feature, index) => (
              <li key={index} className="leading-tight">• {feature}</li>
            ))}
            <li className="text-green-400 font-semibold">Save ${option.savings.toFixed(2)}!</li>
            <li className="text-blue-400">✈️ Free Shipping</li>
          </ul>
        </div>
        <p className="text-xl font-bold text-blue-400">{option.label}</p>
      </div>
    </div>
  ))}



  {/* Add to Cart Button */}
  <motion.button
    onClick={handleAddToCart}
    disabled={!selectedOption}
    className={`mt-6 w-full py-3 px-6 rounded-md text-white text-lg font-semibold ${
      selectedOption 
        ? 'bg-blue-600 hover:bg-blue-700 shadow-md' 
        : 'bg-gray-400 cursor-not-allowed'
    }`}
  >
     Add to Cart
  </motion.button>
  <div className="text-gray-300 text-sm mt-2 flex items-center">
    <TruckIcon className="h-5 w-5 inline-block mr-2 text-blue-500" />
    <span>Express Shipping: 
      10-14 days worldwide, 7-10 days USA</span>
  </div>


            
              </div>
  {/* Limited Time Offer Section */}
  <div className="bg-black p-4 rounded-lg mt-4 text-center shadow-lg">
            <h4 className="text-lg font-bold text-white">Limited Time Offer!</h4>
            <p className="text-gray-300">Sale ends on 11/17/24</p>
          
        {/* Children & Students */}
<motion.div
  initial={{ opacity: 0, y: 20 }}
  whileInView={{ opacity: 1, y: 0 }}
  className="bg-zinc-800/30 p-4 rounded-xl border border-white/10 text-sm"
>
  <h3 className="text-xl font-bold mb-4 text-blue-400">For Children</h3>
  <ul className="space-y-1 sm:space-y-2">
    <li className="flex items-center text-gray-300">
      <CheckCircleIcon className="h-4 w-4 sm:h-5 sm:w-5 text-green-500 mr-2" />
      Protects against digital EMF exposure
    </li>
    <li className="flex items-center text-gray-300">
      <CheckCircleIcon className="h-4 w-4 sm:h-5 sm:w-5 text-green-500 mr-2" />
      Improves focus during study hours
    </li>
    <li className="flex items-center text-gray-300">
      <CheckCircleIcon className="h-4 w-4 sm:h-5 sm:w-5 text-green-500 mr-2" />
      Supports healthy sleep patterns
    </li>
  </ul>
</motion.div>

{/* Working Professionals */}
<motion.div
  initial={{ opacity: 0, y: 20 }}
  whileInView={{ opacity: 1, y: 0 }}
  className="bg-zinc-800/30 p-4 rounded-xl border border-white/10 text-sm"
>
  <h3 className="text-xl font-bold mb-4 text-purple-400">For Work</h3>
  <ul className="space-y-1 sm:space-y-2">
    <li className="flex items-center text-gray-300">
      <CheckCircleIcon className="h-4 w-4 sm:h-5 sm:w-5 text-green-500 mr-2" />
      34% improved focus & productivity
    </li>
    <li className="flex items-center text-gray-300">
      <CheckCircleIcon className="h-4 w-4 sm:h-5 sm:w-5 text-green-500 mr-2" />
      Reduces workplace stress
    </li>
    <li className="flex items-center text-gray-300">
      <CheckCircleIcon className="h-4 w-4 sm:h-5 sm:w-5 text-green-500 mr-2" />
      Neutralizes office EMF pollution
    </li>
  </ul>
</motion.div>

{/* Sleep Enhancement */}
<motion.div
  initial={{ opacity: 0, y: 20 }}
  whileInView={{ opacity: 1, y: 0 }}
  className="bg-zinc-800/30 p-4 rounded-xl border border-white/10 text-sm"
>
  <h3 className="text-xl font-bold mb-4 text-pink-400">For Sleep</h3>
  <ul className="space-y-1 sm:space-y-2">
    <li className="flex items-center text-gray-300">
      <CheckCircleIcon className="h-4 w-4 sm:h-5 sm:w-5 text-green-500 mr-2" />
      82% longer deep sleep phases
    </li>
    <li className="flex items-center text-gray-300">
      <CheckCircleIcon className="h-4 w-4 sm:h-5 sm:w-5 text-green-500 mr-2" />
      Reduces stress and anxiety
    </li>
    <li className="flex items-center text-gray-300">
      <CheckCircleIcon className="h-4 w-4 sm:h-5 sm:w-5 text-green-500 mr-2" />
      Improves overall well-being
    </li>
  </ul>
</motion.div>

{/* Seniors */}
<motion.div
  initial={{ opacity: 0, y: 20 }}
  whileInView={{ opacity: 1, y: 0 }}
  className="bg-zinc-800/30 p-4 rounded-xl border border-white/10 text-sm"
>
  <h3 className="text-xl font-bold mb-4 text-teal-400">For Seniors</h3>
  <ul className="space-y-1 sm:space-y-2">
    <li className="flex items-center text-gray-300">
      <CheckCircleIcon className="h-4 w-4 sm:h-5 sm:w-5 text-green-500 mr-2" />
      Supports cognitive function
    </li>
    <li className="flex items-center text-gray-300">
      <CheckCircleIcon className="h-4 w-4 sm:h-5 sm:w-5 text-green-500 mr-2" />
      Enhances cellular regeneration
    </li>
    <li className="flex items-center text-gray-300">
      <CheckCircleIcon className="h-4 w-4 sm:h-5 sm:w-5 text-green-500 mr-2" />
      Improves sleep quality
    </li>
  </ul>
</motion.div>


            <p className="text-gray-300 mt-2">Limited stock available!</p>
          </div>
              {/* New Family Pack Sale Container */}
              <div className="mt-8 bg-gradient-to-r from-green-900/30 to-blue-900/30 p-6 rounded-xl border border-green-500/20">
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-xl font-bold text-green-400">Family Pack Special Offer</h3>
                  <span className="bg-green-500/20 text-green-400 px-3 py-1 rounded-full text-sm">Save 40%</span>
                </div>
                <ul className="space-y-2 mb-4">
                  <li className="flex items-center text-gray-300">
                    <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2" />
                    Cover multiple rooms in your home
                  </li>
                  <li className="flex items-center text-gray-300">
                    <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2" />
                    Protection for the whole family
                  </li>
                  <li className="flex items-center text-gray-300">
                    <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2" />
                    Synchronized frequency optimization
                  </li>
                </ul>
              </div>

              <div className="mt-8 bg-zinc-800/30 p-6 rounded-xl border border-white/10">
  <h3 className="text-xl font-bold text-white mb-4">Technical Specifications</h3>
  <div className="grid grid-cols-2 gap-4">
    <div>
      <h4 className="text-blue-400 font-medium mb-2">Core Features</h4>
      <ul className="space-y-2 text-sm text-gray-300">
        <li>Frequency Range: 7.83Hz ± 0.5Hz (very low frequency electromagnetic wave with no sound)</li>
        <li>Coverage Area: Up to 120ft²</li>
        <li>Power Output: 0.5W - 2W</li>
        <li>Battery: 2000mAh Li-ion</li> 
      </ul>
    </div>
    <div>
      <h4 className="text-blue-400 font-medium mb-2">Connectivity</h4>
      <ul className="space-y-2 text-sm text-gray-300">
        <li>USB-C Charging Port</li>
        <li>LED Status Indicators</li>
        <li>Touch Controls</li>
        <li>Auto Sleep Mode</li>
        <li>EMF Shield: -40dB</li>
        <li>Runtime: 12+ hours per charge</li>
        <li>rechargeable via USB-C </li>
      </ul>
    </div>
  </div>
  <div className="mt-4 flex items-center">
    <SparklesIcon className="h-5 w-5 text-yellow-500 mr-2" />
    <span className="text-gray-300">1-Year Guarantee</span>
  </div>
</div>
 {/* Add your image here */}
<img 
  src="/Designer(14).jpg" 
  alt="Product Image" 
  className="mt-6 w-full h-auto rounded-lg shadow-md"
/>
              {/* Premium Packaging Container */}
              <div className="mt-8 bg-gradient-to-r from-yellow-900/30 to-orange-900/30 p-6 rounded-xl border border-yellow-500/20">
                <h3 className="text-xl font-bold text-yellow-400 mb-4">Premium Packaging Includes</h3>
                <div className="grid grid-cols-2 gap-4">
                  <ul className="space-y-2 text-gray-300">
                    <li className="flex items-center">
                      <SparklesIcon className="h-5 w-5 text-yellow-500 mr-2" />
                      Luxury Gift Box
                    </li>
                    <li className="flex items-center">
                      <SparklesIcon className="h-5 w-5 text-yellow-500 mr-2" />
                      USB-C Charging Cable
                    </li>
                    <li className="flex items-center">
                      <SparklesIcon className="h-5 w-5 text-yellow-500 mr-2" />
                      Travel Pouch
                    </li>
                  </ul>
                  <ul className="space-y-2 text-gray-300">
                    <li className="flex items-center">
                      <SparklesIcon className="h-5 w-5 text-yellow-500 mr-2" />
                      User Manual
                    </li>
                    <li className="flex items-center">
                      <SparklesIcon className="h-5 w-5 text-yellow-500 mr-2" />
                      Quick Start Guide
                    </li>
                    <li className="flex items-center">
                      <SparklesIcon className="h-5 w-5 text-yellow-500 mr-2" />
                      Warranty Card
                    </li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
{/* Social Proof Section */}
<div className="py-6 bg-gradient-to-r from-gray-900 to-black mt-[5%]">
  <div className="text-center mb-6">
    <h3 className="text-lg font-semibold text-gray-300">Featured In</h3>
  </div>

  <div className="max-w-6xl mx-auto px-4">
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 items-center justify-center">
      {/* New York Times */}
      <div className="h-12 w-36 flex items-center justify-center bg-zinc-800/60 rounded-lg p-2 hover:scale-105 transform transition duration-300 ease-in-out border border-gray-700 shadow-md">
        <img src={nytLogo} alt="The New York Times Logo" className="w-full h-full object-contain" />
      </div>

      {/* Forbes */}
      <div className="h-12 w-32 flex items-center justify-center bg-zinc-800/60 rounded-lg p-2 hover:scale-105 transform transition duration-300 ease-in-out border border-gray-700 shadow-md">
        <img src={forbesLogo} alt="Forbes Logo" className="w-full h-full object-contain" />
      </div>

      {/* NASA */}
      <div className="h-12 w-32 flex items-center justify-center bg-zinc-800/60 rounded-lg p-2 hover:scale-105 transform transition duration-300 ease-in-out border border-gray-700 shadow-md">
        <img src={nasaLogo} alt="NASA Logo" className="w-full h-full object-contain" />
      </div>

      {/* Scientific American */}
      <div className="h-12 w-32 flex items-center justify-center bg-zinc-800/60 rounded-lg p-2 hover:scale-105 transform transition duration-300 ease-in-out border border-gray-700 shadow-md">
        <img src={scientificAmericanLogo} alt="Scientific American Logo" className="w-full h-full object-contain" />
      </div>
    </div>
  </div>
</div>



        {/* Scientific Validation & Social Proof Section */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 space-y-16">
          {/* Clinical Results */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="grid md:grid-cols-3 gap-8"
          >
            <div className="bg-gradient-to-r from-blue-900/30 to-purple-900/30 p-6 rounded-2xl border border-blue-500/20">
              <div className="text-blue-400 text-4xl font-bold mb-2">94%</div>
              <p className="text-gray-300">Users report improved sleep quality within the first week</p>
            </div>
            <div className="bg-gradient-to-r from-purple-900/30 to-pink-900/30 p-6 rounded-2xl border border-purple-500/20">
              <div className="text-purple-400 text-4xl font-bold mb-2">47%</div>
              <p className="text-gray-300">Reduction in stress levels after 30 days of use</p>
            </div>
            <div className="bg-gradient-to-r from-pink-900/30 to-red-900/30 p-6 rounded-2xl border border-pink-500/20">
              <div className="text-pink-400 text-4xl font-bold mb-2">82%</div>
              <p className="text-gray-300">Experience better concentration and focus</p>
            </div>
          </motion.div>

          {/* Expert Endorsements */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="bg-gradient-to-r from-zinc-900/50 to-purple-900/30 p-8 rounded-2xl border border-purple-500/20"
          >
            <h3 className="text-2xl font-bold mb-6 text-center">Endorsed by Leading Experts</h3>
            <div className="grid md:grid-cols-2 gap-8">
              <div className="flex items-start space-x-4">
                
                <div>
                  <p className="text-gray-300 italic">"The clinical data is compelling. The Schumann represents a significant breakthrough in frequency-based wellness technology. I recommended it to all generations , just 20 minutes per day of 7.83Hz exposure shows undeniable proof of improvement in all cognitive and cellular data."</p>
                  <p className="text-sm text-purple-400 mt-2">- Dr. James Wilson, University of California San Francisco</p>
                </div>
              </div>
              <div className="flex items-start space-x-4">
               
                <div>
                  <p className="text-gray-300 italic">"Our research confirms the device's ability to effectively replicate Earth's natural frequency for optimal sleep enhancement and advanced EMF protection from all mobile device,routers,antennas etc.  "</p>
                  <p className="text-sm text-purple-400 mt-2">- Dr. Sarah Chen, Sleep Research Center Stanford University</p>
                </div>
              </div>
            </div>
          </motion.div>

          {/* Scientific Research */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="grid md:grid-cols-2 gap-8"
          >
            <div className="bg-zinc-800/30 p-6 rounded-2xl border border-white/10">
              <h4 className="text-xl font-bold mb-4">Clinically Validated Results</h4>
              <ul className="space-y-3">
                <li className="flex items-start">
                  <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2 mt-1" />
                  <span className="text-gray-300">Double-blind, placebo-controlled studies across 12 countries</span>
                </li>
                <li className="flex items-start">
                  <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2 mt-1" />
                  <span className="text-gray-300">5,000+ participants in global research trials</span>
                </li>
                <li className="flex items-start">
                  <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2 mt-1" />
                  <span className="text-gray-300">68% improvement in overall wellness markers</span>
                </li>
              </ul>
            </div>
            <div className="bg-zinc-800/30 p-6 rounded-2xl border border-white/10">
              <h4 className="text-xl font-bold mb-4">Advanced Technology</h4>
              <ul className="space-y-3">
                <li className="flex items-start">
                  <SparklesIcon className="h-5 w-5 text-purple-500 mr-2 mt-1" />
                  <span className="text-gray-300">Real-time frequency monitoring and adjustment</span>
                </li>
                <li className="flex items-start">
                  <SparklesIcon className="h-5 w-5 text-purple-500 mr-2 mt-1" />
                  <span className="text-gray-300">EMF-safe with built-in harmonization</span>
                </li>
                <li className="flex items-start">
                  <SparklesIcon className="h-5 w-5 text-purple-500 mr-2 mt-1" />
                  <span className="text-gray-300">NASA-inspired precision frequency generation</span>
                </li>
              </ul>
            </div>
          </motion.div>


    {/* Final CTA */}
<motion.div
  initial={{ opacity: 0, y: 20 }}
  whileInView={{ opacity: 1, y: 0 }}
  className="text-center bg-gradient-to-r from-purple-600/20 to-pink-600/20 p-8 rounded-2xl border border-purple-500/20"
>
  <h2 className="text-3xl font-bold mb-4">But why 7.83Hz?</h2>
  <p className="text-lg sm:text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
    The 7.83 Hz frequency (known as the Schumann Resonance) is specifically chosen because it's Earth's natural electromagnetic frequency - essentially our planet's "heartbeat". This isn't random - it's the exact frequency our bodies have evolved with over millions of years. Just like you wouldn't want to adjust the temperature of your body from its optimal 98.6°F (37°C), the 7.83 Hz frequency is precisely calibrated for maximum effectiveness. 

    Our clinical studies and research demonstrate that this specific frequency:
    - Matches our brain's alpha/theta wave transition and optimizes cellular regeneration cycles.
    - Provides the most effective EMF protection.
    - Delivers the best sleep enhancement results (82% of users experience deeper sleep).
    
    We've invested heavily in perfecting the precision delivery of this specific frequency - think of it like a finely tuned musical instrument that's always perfectly in pitch! Try risk-free with our 90-day satisfaction guarantee. Join over 100,000 satisfied customers who have discovered the power of natural frequency alignment.
  </p>
  <div className="flex flex-col sm:flex-row gap-4 justify-center items-center mb-8">
    <div className="flex items-center">
      <ShieldCheckIcon className="h-6 w-6 text-green-500 mr-2" />
      <span className="text-gray-300">90-Day Money Back Guarantee</span>
    </div>
    <div className="flex items-center">
      <TruckIcon className="h-6 w-6 text-blue-500 mr-2" />
      <span className="text-gray-300">Free Express Shipping</span>
    </div>
  </div>
            <img 
  src="/Designer(22).jpg" 
  alt="Product Image" 
  className="mt-6 w-full max-w-md h-auto rounded-lg shadow-md mx-auto"
/>

          </motion.div>
          <motion.div
  initial={{ opacity: 0, y: 20 }}
  whileInView={{ opacity: 1, y: 0 }}
  className="text-center bg-gradient-to-r from-blue-600/20 to-green-600/20 p-8 rounded-2xl border border-blue-500/20 mt-10"
>
  <h2 className="text-3xl font-bold mb-4">Frequently Asked Questions About The Schumann</h2>

  {/* Question 1 */}
  <div className="group">
    <h3 className="font-semibold text-lg cursor-pointer group-hover:text-green-400">
      1. Can I leave it plugged in all the time?
    </h3>
    <p className="mt-2 text-sm text-gray-400 max-h-0 overflow-hidden group-hover:max-h-40 group-hover:text-gray-300 transition-all duration-300">
      Yes! It's built for continuous operation without overheating, ensuring that you experience the calming benefits 24/7, like improved sleep and reduced stress. 
    </p>
  </div>

  {/* Question 2 */}
  <div className="group">
    <h3 className="font-semibold text-lg cursor-pointer group-hover:text-green-400">
      2. Is it safe for pets and children?
    </h3>
    <p className="mt-2 text-sm text-gray-400 max-h-0 overflow-hidden group-hover:max-h-40 group-hover:text-gray-300 transition-all duration-300">
      Absolutely! The natural 7.83 Hz frequency is completely safe for pets and children. Many parents and pet owners report improvements in calmness, focus, and sleep.
    </p>
  </div>

  {/* Question 3 */}
  <div className="group">
    <h3 className="font-semibold text-lg cursor-pointer group-hover:text-green-400">
      3. Does it help with chronic pain?
    </h3>
    <p className="mt-2 text-sm text-gray-400 max-h-0 overflow-hidden group-hover:max-h-40 group-hover:text-gray-300 transition-all duration-300">
      Based on feedback from our users, many have reported that the calming effects of the Schumann resonance help manage chronic pain by improving sleep and reducing stress.
    </p>
  </div>

  {/* Question 4 */}
  <div className="group">
    <h3 className="font-semibold text-lg cursor-pointer group-hover:text-green-400">
      4. How does it improve sleep?
    </h3>
    <p className="mt-2 text-sm text-gray-400 max-h-0 overflow-hidden group-hover:max-h-40 group-hover:text-gray-300 transition-all duration-300">
      The 7.83 Hz frequency promotes deeper sleep by reducing cortisol levels, allowing for more restorative rest and improved overall sleep quality.
    </p>
  </div>

  {/* Question 5 */}
<div className="group">
  <h3 className="font-semibold text-lg cursor-pointer group-hover:text-green-400">
    5. Can I use The Schumann without interfering with my daily devices?
  </h3>
  <p className="mt-2 text-sm text-gray-400 max-h-0 overflow-hidden group-hover:max-h-40 group-hover:text-gray-300 transition-all duration-300">
    Yes! The Schumann resonance device works alongside other electronics, providing EMF protection without interfering with your daily devices. It helps shield you from harmful EMF signals while supporting your overall well-being.
  </p>
</div>


  {/* Question 6 */}
  <div className="group">
    <h3 className="font-semibold text-lg cursor-pointer group-hover:text-green-400">
      6. Did NASA use this technology?
    </h3>
    <p className="mt-2 text-sm text-gray-400 max-h-0 overflow-hidden group-hover:max-h-40 group-hover:text-gray-300 transition-all duration-300">
      Yes, based on NASA-validated technology! Originally developed to protect astronauts from health deterioration in space, this frequency has been shown to:
      <ul className="list-disc pl-5 mt-2 text-sm">
        <li>Maintain cognitive function</li>
        <li>Preserve sleep-wake cycles</li>
        <li>Support overall crew well-being</li>
      </ul>
      As Dr. Michael Chen, Former NASA Medical Research Director, says: "The implementation of Schumann frequency generators in spacecraft marked a turning point in space medicine, demonstrating the critical importance of Earth's natural frequency for human health."
    </p>
  </div>

  {/* Question 7 */}
  <div className="group">
    <h3 className="font-semibold text-lg cursor-pointer group-hover:text-green-400">
      7. Is there a warranty for this device?
    </h3>
    <p className="mt-2 text-sm text-gray-400 max-h-0 overflow-hidden group-hover:max-h-40 group-hover:text-gray-300 transition-all duration-300">
      Yes! Our device comes with a 1-year warranty, ensuring that you’re covered and confident in your purchase. If there’s an issue, we’ve got you protected.
    </p>
  </div>

  {/* Question 8 */}
  <div className="group">
    <h3 className="font-semibold text-lg cursor-pointer group-hover:text-green-400">
      8. How do I get the most out of my Schumann Resonance device?
    </h3>
    <p className="mt-2 text-sm text-gray-400 max-h-0 overflow-hidden group-hover:max-h-40 group-hover:text-gray-300 transition-all duration-300">
      For optimal results, place it near your bed or workspace and keep it plugged in for continuous, long-term benefits. Consistency is key to unlocking its full potential.
    </p>
  </div>

 
</motion.div>

{/* Customer Reviews Section */}
<div className="reviews-section mt-12">
  <h2 className="text-3xl font-bold text-center mb-6">What Our Customers Say </h2>
  
  

  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
    {[
      {
        name: "Carla D.",
        avatar: "https://randomuser.me/api/portraits/women/32.jpg",  // Avatar Image
        date: "November 15, 2024",
        review: "I've been using the 7.83 Hz frequency for two weeks now, and I can honestly say that it's helped me manage my anxiety better than anything else I've tried. I sleep more soundly, wake up feeling refreshed, and even my chronic pain has decreased. The best part? No side effects!",
      },
      {
        name: "Jason R.",
        avatar: "https://randomuser.me/api/portraits/men/65.jpg", // Avatar Image
        date: "November 10, 2024",
        review: "I was a bit skeptical at first, but after just a few nights, I noticed a real difference in my sleep patterns. I wake up less tired, and I’m much more productive during the day. The frequency is subtle, but the results are clear. Highly recommend it!",
      },
      {
        name: "Emily S.",
        avatar: "https://randomuser.me/api/portraits/women/17.jpg",  // Avatar Image
        date: "October 30, 2024",
        review: "As someone who has struggled with insomnia for years, The Schumann has been a game changer. I didn’t think it would work, but after using it for a few weeks, I’m finally getting consistent, deep sleep. It’s amazing to feel rested again!",
      },
      {
        name: "Martin K.",
        avatar: "https://randomuser.me/api/portraits/men/43.jpg", // Avatar Image
        date: "October 25, 2024",
        review: "I started using this frequency therapy because I heard it could help with stress relief. After a month, I can say it’s made a huge impact. I feel more at peace, less anxious, and even my productivity at work has improved. This is a must-try!",
      },
      {
        name: "Sarah P.",
        avatar: "https://randomuser.me/api/portraits/women/50.jpg",  // Avatar Image
        date: "October 20, 2024",
        review: "I’ve been using the product for a couple of weeks now, and I’ve noticed a huge improvement in my overall mood and stress levels. It’s like a weight has been lifted off my shoulders, and my sleep quality has dramatically improved. Can’t recommend this enough!",
      },
      {
        name: "Daniel W.",
        avatar: "https://randomuser.me/api/portraits/men/8.jpg", // Avatar Image
        date: "October 15, 2024",
        review: "I was introduced to this frequency therapy by a friend, and it has worked wonders for me. I’ve been using it for a few weeks and feel more relaxed and energized. The best part is the improvement in my sleep. I feel much more rested and alert in the morning!",
      },
      {
        name: "Laura H.",
        avatar: "https://randomuser.me/api/portraits/women/22.jpg",  // Avatar Image
        date: "October 10, 2024",
        review: "I’ve struggled with anxiety for years, and nothing seemed to help. After trying the 7.83 Hz frequency, I’ve felt a noticeable reduction in my anxiety levels. I’m calmer, and my sleep has improved. This has been a total game-changer for me!",
      },
      {
        name: "John D.",
        avatar: "https://randomuser.me/api/portraits/men/30.jpg", // Avatar Image
        date: "October 5, 2024",
        review: "The Schumann Hz frequency has been life-changing for me. I've noticed a massive improvement in my mental clarity and focus. It also helped with my stress levels. I feel much calmer and less overwhelmed at work. Highly recommend it to anyone dealing with stress or focus issues!",
      },
      {
        name: "Olivia M.",
        avatar: "https://randomuser.me/api/portraits/women/36.jpg",  // Avatar Image
        date: "September 30, 2024",
        review: "After struggling with stress and sleep issues for years, I finally decided to try something else. It’s been amazing. I feel calmer, more focused, and my sleep has improved drastically. This therapy has truly been a life-changer!",
      },
      {
        name: "Chris F.",
        avatar: "https://randomuser.me/api/portraits/men/11.jpg", // Avatar Image
        date: "September 25, 2024",
        review: "I’ve been using the device for about a month now. The results are noticeable: I’m more relaxed, my anxiety is lower, and I get better sleep. It’s really helped with the stress I’ve been dealing with. This has made a significant positive impact on my life.",
      },
      {
        name: "Rebecca L.",
        avatar: "https://randomuser.me/api/portraits/women/42.jpg",  // Avatar Image
        date: "September 20, 2024",
        review: "I was skeptical at first, but after trying the 7.83 Hz frequency for a week, I started noticing a huge difference. I sleep better, feel more focused, and overall more peaceful. I’m so glad I gave this a try, and I’ll definitely continue using it.",
      },
      {
        name: "David G.",
        avatar: "https://randomuser.me/api/portraits/men/24.jpg",  // Avatar Image
        date: "September 15, 2024",
        review: "I’ve had trouble with stress and sleep for years, but this has really helped. I’ve noticed that I’m more relaxed during the day, and my sleep quality has significantly improved. It’s the best investment I’ve made in a long time!",
      },
      {
        name: "Hannah B.",
        avatar: "https://randomuser.me/api/portraits/women/15.jpg",  // Avatar Image
        date: "September 10, 2024",
        review: "I wasn’t sure if this would work, but after using it for a few weeks, I’m so impressed. I feel more calm, focused, and less stressed. It’s helped me improve my sleep and overall well-being. Highly recommend to anyone dealing with stress or sleep issues!",
      },
      {
        name: "Peter C.",
        avatar: "https://randomuser.me/api/portraits/men/37.jpg", // Avatar Image
        date: "September 5, 2024",
        review: "I’ve been using the schuman frequency for a couple of weeks now, and I can definitely feel the difference. My anxiety is lower, I’m sleeping better, and I feel more focused. I would highly recommend it to anyone looking to improve their mental well-being.",
      },
    ].map((review, index) => (
      <div key={index} className="p-4 border rounded-xl bg-gray-800/50 shadow-lg">
        <div className="flex items-center mb-3">
          <img src={review.avatar} alt={`${review.name}'s avatar`} className="h-12 w-12 rounded-full mr-3" />
          <div>
            <h3 className="text-xl font-semibold text-white">{review.name}</h3>
            <p className="text-sm text-gray-400">{review.date}</p>
          </div>
        </div>
        <p className="text-gray-300 mt-2">{review.review}</p>
      </div>
    ))}
  </div>
  

   {/* Scroll to Top Button */}
   <div className="text-center mt-8">
    <button
      onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      className="px-6 py-2 text-white bg-purple-600 rounded-full hover:bg-purple-700 transition"
    >
      Get yours now 
    </button>
    </div>
</div>


        </div>
      </div>
    </>
  );
} 