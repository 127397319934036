import { motion } from 'framer-motion';
import { BeakerIcon, LightBulbIcon, HeartIcon, AcademicCapIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import Earth3D from './Earth3D';

function About() {
  const navigate = useNavigate();

  return (
    <div className="bg-black text-white min-h-screen">
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="relative overflow-hidden"
      >
        {/* Background Elements */}
        <div className="absolute inset-0 -z-10">
          <div className="absolute inset-0 bg-gradient-to-b from-zinc-900 to-black"></div>
          <motion.div
            animate={{
              scale: [1, 1.2, 1],
              rotate: [0, 180, 360],
            }}
            transition={{
              duration: 20,
              repeat: Infinity,
              ease: "linear"
            }}
            className="absolute inset-0 opacity-20"
          >
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[800px] h-[800px] bg-purple-500/30 rounded-full blur-[120px]"></div>
          </motion.div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-32">
          {/* Mission Section */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="text-center mb-32"
          >
            <h1 className="text-5xl md:text-6xl font-bold mb-8 bg-gradient-to-r from-purple-400 to-pink-600 text-transparent bg-clip-text">Our Mission</h1>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto leading-relaxed">
              At Schumann, we're dedicated to bringing the healing power of Earth's natural frequency 
              into your daily life. Our mission is to help people reconnect with nature's rhythm in 
              our modern world, enhancing both physical and mental well-being through innovative technology.
            </p>
          </motion.div>

          {/* Global Presence Section */}
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            className="mb-32 bg-zinc-800/30 rounded-2xl p-8 border border-white/10"
          >
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-4xl font-bold mb-6">Global Presence</h2>
              <p className="text-xl text-gray-300 mb-8">
                Headquartered in Western Europe, Schumann operates a state-of-the-art research and development facility equipped with cutting-edge technology. With an established network of suppliers and distribution centers worldwide, we ensure efficient delivery of our products to customers across the globe.
              </p>
              <motion.button
                onClick={() => navigate('/blog/science/the-scientific-foundation-of-schumann-resonance')}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="inline-flex items-center space-x-2 bg-gradient-to-r from-purple-500 to-pink-600 px-6 py-3 rounded-full text-white font-medium hover:from-purple-600 hover:to-pink-700 transition-all duration-200"
              >
                <span>Learn More About Our Technology</span>
                <ArrowRightIcon className="h-5 w-5 ml-2" />
              </motion.button>
            </div>
          </motion.div>

          {/* Values Section */}
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            className="grid md:grid-cols-3 gap-8 mb-32"
          >
            {[
              {
                icon: BeakerIcon,
                title: "Scientific Excellence",
                description: "Our work is grounded in rigorous research and validated by leading institutions.",
                color: "from-purple-500 to-blue-500"
              },
              {
                icon: LightBulbIcon,
                title: "Innovation First",
                description: "We continuously push boundaries to create breakthrough solutions.",
                color: "from-blue-500 to-cyan-500"
              },
              {
                icon: HeartIcon,
                title: "Human-Centered",
                description: "Every product we develop focuses on improving human well-being.",
                color: "from-cyan-500 to-teal-500"
              }
            ].map((value, index) => (
              <motion.div
                key={value.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
                className="p-8 rounded-2xl bg-zinc-800/50 backdrop-blur-xl border border-white/10 hover:border-white/20 transition-all duration-300"
              >
                <div className={`bg-gradient-to-r ${value.color} p-3 rounded-lg w-fit mb-6`}>
                  <value.icon className="h-6 w-6 text-white" />
                </div>
                <h3 className="text-xl font-semibold mb-3">{value.title}</h3>
                <p className="text-gray-400">{value.description}</p>
              </motion.div>
            ))}
          </motion.div>

          {/* Expertise Section */}
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            className="mb-32"
          >
            <h2 className="text-4xl font-bold text-center mb-16">Our Expertise</h2>
            <div className="grid md:grid-cols-3 gap-8">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                className="rounded-2xl bg-zinc-800/50 backdrop-blur-xl border border-white/10 p-6"
              >
                <h3 className="text-xl font-semibold mb-4">World-Class Research Team</h3>
                <p className="text-gray-400">Our team includes leading scientists from prestigious institutions including Harvard, MIT, and Stanford, with backgrounds in neuroscience, electromagnetic research, and biophysics.</p>
              </motion.div>
              
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
                className="rounded-2xl bg-zinc-800/50 backdrop-blur-xl border border-white/10 p-6"
              >
                <h3 className="text-xl font-semibold mb-4">Engineering Excellence</h3>
                <p className="text-gray-400">Our engineering team brings decades of experience from leading tech companies and space agencies, ensuring cutting-edge product development and innovation.</p>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
                className="rounded-2xl bg-zinc-800/50 backdrop-blur-xl border border-white/10 p-6"
              >
                <h3 className="text-xl font-semibold mb-4">Global Operations</h3>
                <p className="text-gray-400">With research facilities in Western Europe and a worldwide distribution network, we maintain the highest standards of quality while ensuring efficient delivery to customers globally.</p>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
}

export default About;